import axios from "axios";
import { redirect } from "next/navigation";

// Create an Axios instance
const authAxiosInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_SERVER_API_URL}${process.env.NEXT_PUBLIC_API_VERSION}`,
});

// Request Interceptor
authAxiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const response = await axios.get<{ accessToken: string }>(
        `${process.env.NEXT_PUBLIC_CLIENT_API_URL}/api/getAccessToken`
      );
      const accessToken = response.data.accessToken;

      if (process.env.NODE_ENV === "development") {
        console.log("Access Token:", accessToken);
      }

      console.log("Access Token:", accessToken);

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    } catch (error) {
      console.error("Error getting access token:", error);
      redirect("/api/auth/login");
    }
    return config;
  },
  (error) => {
    // Handle the request error here
    return Promise.reject(error);
  }
);

export default authAxiosInstance;
