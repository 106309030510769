import { create } from "zustand";

interface ISettings {
  isSidebarOpen: boolean;
  language: string;
}

interface State {
  settings: ISettings;
}

interface Actions {
  selectSettings: (Setting: ISettings) => void;
  setLanguage: (language: string) => void;
}

const INITIAL_STATE: State = {
  settings: {
    isSidebarOpen: true,
    language: "en",
  },
};

export const useSettingsStore = create<State & Actions>(
  (set: any, get: any) => ({
    settings: INITIAL_STATE.settings,
    selectSettings: (settings: ISettings) =>
      set((state: State) => ({
        settings: {
          ...state.settings,
          ...settings,
        },
      })),
    setLanguage: (language: string) =>
      set((state: State) => ({
        settings: {
          ...state.settings,
          language,
        },
      })),
  })
);
